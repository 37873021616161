let corpId = 'ding6c1097b9a618fae535c2f4657eb6378f';
const agentId = '346082415';
const userId = '201003472820698800';
let assignable;
let auditers;
let billable;

// const qinweiliu = {
//   avatar: '',
//   name: '覃伟柳',
//   userId: '183661504034484599'
// };

const yujinxian = {
  avatar: '',
  name: '余金先',
  userId: '201003472820698800'
};

if (
  process.env.NODE_ENV == 'development' ||
  process.env.NODE_ENV == 'localhost'
) {
  // 开发版
  assignable = ['李莹莹'];
  auditers = {
    budget: yujinxian,
    cfo: yujinxian
  };
  assignable = ['李莹莹'];
  billable = ['李莹莹'];
} else {
  // 正式版
  auditers = {
    budget: {
      name: '蒋才义',
      avatar: '',
      userId: '236469103333407847'
    },
    cfo: {
      name: '吴建军',
      avatar: '',
      userId: '236533414921489941'
    }
  };
  assignable = ['李莹莹'];
  billable = [
  ];
}

export default {
  corpId,
  agentId,
  assignable,
  auditers,
  billable,
  userId
};
